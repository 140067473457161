import Hero from "../layouts/Home/Hero";
import OverView from "../layouts/Home/Overview";
import Partners from "../layouts/Home/Partners";
import AirportTransfers from "../layouts/Home/AirportTransfers"
export default function Home() {
  return (
    <section>
      <Hero />
      <OverView />
      <AirportTransfers />
      <Partners />
    </section>
  );
}
