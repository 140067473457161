import React from "react";
import { blogData } from "../Lib/blogs";
import "../styles/Blogs.scss";
import { Link } from "react-router-dom";

export default function Blogs() {
  return (
    <div className="blog-page">
       <h1>Your Guide to London Airport Transfers & Travel</h1>
       <h2>Expert Advice on Heathrow, Gatwick & Stansted Airport Transfers</h2>
      <section className="featured-blogs">
        {blogData.featured.map((blog, index) => (
          <Link
            to={`/blogs/${blog.slug}`}
            key={blog.slug}
            className={`featured-blog featured-blog-${index + 1}`}
          >
            <img src={blog.img} alt={blog.heading} />
            <div className="blog-content">
              <h2>{blog.heading}</h2>
              <p>{blog.desc}</p>
              <span className="date">{blog.date}</span>
            </div>
          </Link>
        ))}
      </section>

      <section className="blog-list">
        <div className="blog-container">
          {blogData.blogs.map((blog) => (
            <Link
              to={`/blogs/${blog.slug}`}
              key={blog.slug}
              className="blog-card"
            >
              <img src={blog.img} alt={blog.heading} />
              <div className="blog-content">
                <h3>{blog.heading}</h3>
                <p>{blog.desc}</p>
                <span className="date">{blog.date}</span>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
}
