// dependencies
import * as React from 'react';
import style from "../../styles/Home.module.scss";
import Tab from '@mui/material/Tab';
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import  PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  export default function AirportTransfers() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (

    <section className="container">
        <div className={style.partnersContainer}>      
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Airport Tranfers">
                    <Tab label="HEATHROW AIRPORT" {...a11yProps(0)} />
                    <Tab label="LUTON AIRPORT" {...a11yProps(1)} />
                    <Tab label="GATWICK AIRPORT" {...a11yProps(2)} />
                    <Tab label="STANSTED AIRPORT" {...a11yProps(3)} />
                    <Tab label="CITY AIRPORT" {...a11yProps(4)} />
                </Tabs>
                </Box>
                
                <CustomTabPanel value={value} index={0}>
                <h3>MINICAB TO HEATHROW AIRPORT TOO EXPENSIVE?</h3>
                <br></br>
                <p>
                    We get it, that's why we're here 24/7 to offer one of London's best value and cheapest airport transfer services in London. Additionally, our driver partners are following extra precautions to ensure everyone is transferred safely and securely.
                    <br></br><br></br>
                    There really is no need to worry about your <Link to="/heathrow-airport-transfers">Heathrow Airport Transfers</Link> with Airport Bee whether its a long haul flight from the <Link to="/us">US</Link> or a short haul flight we will help make your airport journey and transfer relaxed and stress free. You can book your Heathrow transfer quickly and easily online, by chat, by phone or via our mobile app. We have vehicles for all types of journeys.<br></br><br></br>All our vehicles are fully licensed and insured, authorised by Transport for London. A wide range of vehicles are on offer including standard clean saloons, spacious people and comfy carriers and even sleek executive vehicles. Our drivers NEVER have an expectation of a tip at all.<br></br><br></br><b>Are you thinking of going on a tour?</b><br></br>
                    Don't pay crazy prices to see London, when one of our private hire vehicles (including a driver) can provide you and your loved ones an experience of a 
                    lifetime. Call us for bespoke requirements, you'll be amazed by our great prices.
                </p>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                <h3>MINICAB TO LUTON AIRPORT NOT WORTH THE PRICE?</h3>
                <br></br>
                <p>
                    An airport transfer from London to Luton Airport need not be a hassle or expensive by any means.  Infact, our minicabs are much cheaper than other operators and   usually 10% to 15% less.  Try our online booking system and you might be surprised how much value we provide for your next <Link to="/luton-airport-transfers">Luton Airport Transfers</Link>.
                </p>
                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={2}>
                <h3>MINICAB TO GATWICK AIRPORT NOT WITHIN BUDGET?</h3>
                <br></br>
                <p>
                    We're surprised too and that is why 1000's of travelers like you use Airport Bee to book a reliable <Link to="/gatwick-airport-transfers">Gatwick Airport Transfers</Link>.  We're a privately owned business and take pride in our personalized service for passengers all over the world.  Whether you are traveling to the Far East, Europe or the Middle East and Americas, we are here to take you there.
                </p>
                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={3}>
                <h3>MINICAB TO STANSTED AIRPORT A RIP-OFF?</h3>
                <br></br>
                <p>   
                    We offer an airport transfer to or from Stansted Airport and we guarantee you'll be delighted with our level of service.
                </p>
                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={4}>
                <h3>MINICAB TO LONDON CITY AIRPORT OVERPRICED?</h3>
                <br></br>
                <p>
                    London City Airport is small compared with several other airports serving London however it is very popular. Our minicabs are similarly very popular, even when compared to the widely known 'competitors'. The reason for that is simple. We leave the competition in the dust by offering a fixed price service without the usual extra charges.
                </p>
                </CustomTabPanel>

            </Box>
        </div>
    </section>
    );
  }