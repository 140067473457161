import { useParams } from "react-router-dom";
import { blogData } from "../Lib/blogs";
export default function Blog() {
  const { slug } = useParams();
  const blogs = [...blogData.featured, ...blogData.blogs];
  const blog = blogs.find((item) => item.slug === slug);

  if (!blog) {
    return <section className="blog-not-found">Blog post not found</section>;
  }

  return (
    <article className="blog-content">
      <header className="blog-header">
        <div className="blog-header-content">
          <h1>{blog.heading}</h1>
          <p className="blog-date">{blog.date}</p>
        </div>
        <img src={blog.img} alt={blog.heading} className="blog-header-image" />
      </header>
      <div className="blog-body">
        <p className="blog-description">{blog.desc}</p>
        {blog.content &&
          blog.content.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
      </div>
    </article>
  );
}
