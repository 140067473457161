import style from "../styles/about.module.scss";
import { aboutUs } from "../Lib/aboutUs";
import { Link } from "react-router-dom";
import aboutusBanner from "../assets/images/aboutus.jpg";
export default function AboutUs() {
  return (
    <section>
      <div
        className={style.bannerSection}
        style={{ backgroundImage: `url(${aboutusBanner})` }}
      ></div>
      <section className={style.content + " container"}>
        
        <ul className={style.storySection}>
        <h1>Your Go-To for London Airport Transfers</h1>
        <h2>Punctual, Affordable Minicab Services to Heathrow, Gatwick & Stansted</h2>
          {aboutUs.map((item, indx) => (
            <li key={indx}>
              <h3>{item.heading}</h3>
              <p>{item.content}</p>
            </li>
          ))}
        </ul>
        <div className={style.whySection}>
          <h4>WHY BOOK WITH US?</h4>
          <ul className={style.whylist}>
            <li>
              <h3>Reliable and Safe</h3>
              <p>
                Our drivers are insured and PCO certified - just like you would
                expect. However, we also spend time training our drivers. How
                many other private hire companies do that?
              </p>
            </li>
            <li>
              <h3>No hidden fees</h3>
              <p>
                You pay for what you use. With different payment options,
                including cash - using a Airport Bee driver even helps your
                wallet!
              </p>
            </li>
            <li>
              <h3>We’re Always Here</h3>
              <p>
                Our office is open 24 hours a day - even on public holidays.
                This means you can count on us for your questions whatever the
                time of day or year.
              </p>
            </li>
            <Link to="/booknow" className={style.bookNow}>
              Book Now
            </Link>
          </ul>
        </div>
      </section>
    </section>
  );
}
