/*
Author: Umer Shabir,
Date:28-10-2023,
File: this is the main page of booking flow, 
first flow is to search location, a modal will appear if use select airport pickup or drop as an option we will change the search list,other wise get address api has been used get the address
Second: I am getting the where from location searched and where to go, getting ther data and sending to check vehicles list, 
Third: selecting the vehicle 
forth: getting values from the booking form and select the value from the cash method and book the booking.
*/
// dependencies

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import axios from "axios";
import style from "../styles/BookNow.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_ADDRESS_API, GET_SPECIFIC_ADDRESS_API } from "../config";
import { Button } from "@mui/material";
import BookingForm from "../layouts/BookNow/BookingForm";
import VehicleList from "../layouts/BookNow/VehicleList.jsx";
import successGif from "../assets/success.gif";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import logo from "../assets/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import { airportsList } from "../Lib/airportList.js";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MenuItem from "@mui/material/MenuItem";
import { BASE_URL } from "../config";
/// style of the modal container
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: 4,
};
// where from address bar
function WhereFromAddress({ setWhereAddress, airportValue }) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  // searching function
  const handleSearch = async (value) => {
    if (airportValue === "location" || airportValue === "droping") {
      await axios.get(GET_ADDRESS_API(value)).then((res) => {
        setShow(true);
        setOptions(res.data.suggestions);
      });
    }
  };
  useEffect(() => {
    if (airportValue === "taking") {
      setOptions(airportsList);
    } else {
      setOptions([]);
    }
  }, [airportValue]);

  return (
    <>
      {airportValue === "taking" ? (
        <Autocomplete
          id="searching-location"
          options={options}
          fullWidth
          autoComplete={false}
          onChange={(event, newValue) => {
            setWhereAddress(newValue);
          }}
          onInputChange={(e) => {
            if (e.target.value) {
              handleSearch(e?.target?.value);
            }
          }}
          getOptionLabel={(option) => option?.address}
          renderOption={(props, options) => (
            <Box component="li" {...props} key={options?.url}>
              {options?.address}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                airportValue === "taking"
                  ? "Select Airport By Name"
                  : "Where to Pick you"
              }
              fullWidth
              disableClearaAble
            />
          )}
        />
      ) : (
        <>
          <div className={style.customSearchContainer}>
            <TextField
              value={value}
              label="Where to Pick you"
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value) {
                  handleSearch(e?.target?.value);
                }
              }}
            />
            {show && (
              <div className={style.customSearchList}>
                {options.map((item, indx) => (
                  <MenuItem
                    key={indx}
                    onClick={() => {
                      setValue(item.address);
                      setWhereAddress(item);
                      setShow(false);
                    }}
                  >
                    {item.address}
                  </MenuItem>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
// To address search bar
function ToAddress({ setToAddress, airportValue }) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const handleSearch = (value) => {
    if (airportValue === "location" || airportValue === "taking") {
      axios.get(GET_ADDRESS_API(value)).then((res) => {
        setShow(true);
        setOptions(res.data.suggestions);
      });
    }
  };
  useEffect(() => {
    if (airportValue === "droping") {
      setOptions(airportsList);
    } else setOptions([]);
  }, [airportValue]);
  return (
    <>
      {airportValue === "droping" ? (
        <Autocomplete
          id="searching-medicine"
          getOptionLabel={(option) => option?.address}
          options={options}
          autoComplete
          fullWidth
          includeInputInList
          filterSelectedOptions
          onChange={(event, newValue) => {
            setToAddress(newValue);
          }}
          onInputChange={(e) => {
            handleSearch(e?.target?.value);
          }}
          renderOption={(props, options) => (
            <Box component="li" {...props} key={options?.address}>
              {options?.address}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                airportValue === "droping"
                  ? "Select Airport By Name"
                  : "Where to drop you"
              }
              fullWidth
              disableClearaAble
            />
          )}
        />
      ) : (
        <>
          <div className={style.customSearchContainer}>
            <TextField
              value={value}
              label="Where to Drop you"
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value) {
                  handleSearch(e?.target?.value);
                }
              }}
            />
            {show && (
              <div className={style.customSearchList}>
                {options.map((item, indx) => (
                  <MenuItem
                    key={indx}
                    onClick={() => {
                      setValue(item.address);
                      setToAddress(item);
                      setShow(false);
                    }}
                  >
                    {item.address}
                  </MenuItem>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
export default function BookNow() {
  // all the variables
  const [whereAddress, setWhereAddress] = useState({});
  const [toAddress, setToAddress] = useState({});
  const [whereAddressData, setWhereAddressData] = useState({});
  const [toAddressData, setToAddressData] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [quoteData, setQuoteData] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [formScreen, setFormScreen] = useState(false);
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [vehicleType, setVehicleType] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [pickupTime, setPickupTime] = useState("");
  const [newLoading, setNewLoading] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [open, setOpen] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [airportValue, setAirportValue] = useState("location");

  const handleRadioChange = (event) => {
    setAirportValue(event.target.value);
    // console.log(event.target.value);
  };
  // all the functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  // getting token credentials
  const getToken = async () => {
    try {
      await axios
        .post(
          BASE_URL + "/gettoken",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          setToken(response.data);
          return response.data;
        });
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
    // try {
    //   const response = await axios.post(
    //     `https://quote.cordicgateway.net/Quotation/?credentials`,
    //     {
    //       account: "DELIVER2U",
    //       password: "*(1alr(?26!2jgo2c",
    //       username: "d2u-quote",
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       mode: "no-cors",
    //     }
    //   );
    //   // console.log(response.data.jwt);
    //   setToken(response.data.jwt);
    //   return response.data.jwt;
    // } catch (error) {
    //   setLoading(false);
    //   console.error(error);
    //   return false;
    // }
  };

  // get Where Address Data
  const getWhereAddressData = async () => {
    await axios
      .get(GET_SPECIFIC_ADDRESS_API(whereAddress.id))
      .then((res) => {
        setWhereAddressData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  // get To Address Data
  const getToAddressData = async () => {
    await axios
      .get(GET_SPECIFIC_ADDRESS_API(toAddress.id))
      .then((res) => {
        // console.log(res.data);
        setToAddressData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  // get available cars
  const getQuotePrice = async (flag) => {
    if (flag) {
      setNewLoading(true);
    } else {
      setVehicleType([]);
    }
    const data = {
      pickup: {
        address: whereAddressData?.formatted_address.join(","),
        postcode: whereAddressData?.postcode,
        latitude: whereAddressData?.latitude,
        longitude: whereAddressData?.longitude,
      },
      dropoff: {
        address: toAddressData?.formatted_address.join(","),
        postcode: toAddressData?.postcode,
        latitude: toAddressData?.latitude,
        longitude: toAddressData?.longitude,
      },
      vehicleTypes: vehicleType,
      attributes: attributes,
      pickupTime: pickupTime,
    };
    // https request for getting quote
    await axios
      .post(
        `${BASE_URL}/getCarData`,
        {
          ...data,
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setNewLoading(false);
        setQuoteData(res.data);
        setGoBack(true);
        if (res.data?.length < 1) {
          setEmpty(true);
        }
        if (!flag) {
          setFormScreen(false);
        }
        if (flag) {
          setVehicleDetail(res.data[0]);
        }
      })
      .catch((err) => {
        console.error("error" + err);
        setLoading(false);
        setNewLoading(false);
      });
    // await axios
    //   .post(`https://quote.cordicgateway.net/Quotation/?getquotes`, data, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    //   .then((res) => {
    //     setLoading(false);
    //     setNewLoading(false);
    //     setQuoteData(res.data);
    //     setGoBack(true);
    //     if (res.data?.quotes?.length < 1) {
    //       setEmpty(true);
    //     }
    //     if (!flag) {
    //       setFormScreen(false);
    //     }
    //     if (flag) {
    //       setVehicleDetail(res.data.quotes[0]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.error("error" + err);
    //     setLoading(false);
    //     setNewLoading(false);
    //   });
  };
  // get quote function
  const getQuote = async () => {
    setQuoteData([]);
    setVehicleType([]);
    setLoading(true);
    await getToken().then(async (res) => {
      if (res) {
        if (airportValue === "location" || airportValue === "droping") {
          await getWhereAddressData();
        } else {
          // console.log("set Address");
          setWhereAddressData(whereAddress);
        }

        if (airportValue === "location" || airportValue === "taking") {
          await getToAddressData();
        } else {
          // console.log("set to Address");
          setToAddressData(toAddress);
        }
      } else {
        setEmpty(true);
      }
    });

    // setLoading(false);
  };
  // use effect to get the qoute if we have address and token, this effect is doing when we get where address data and to address data it is calling automatically,
  useEffect(() => {
    // console.log(whereAddressData, toAddressData);
    if (whereAddressData && toAddressData && token) {
      getQuotePrice();
    }
  }, [whereAddressData, toAddressData]);
  // use effect to get quote again if user add any attribute to them
  useEffect(() => {
    if (attributes || pickupTime) {
      getQuotePrice(true);
    }
  }, [attributes, pickupTime]);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);
  // console.log
  // console.log(vehicleDetail);
  return (
    <>
      <section>
        {!successScreen ? (
          <div className="container">
            {/* searching container */}
            <div className={style.searchContainer}>
            <h1>Quick & Easy Minicab Booking for Heathrow, Gatwick & Stansted</h1>
            <h2>Book Reliable Minicabs for Your Next Airport Transfer</h2>
              <h3> Journey Details:</h3>
              <div className={style.searchBar}>
                <WhereFromAddress
                  setWhereAddress={setWhereAddress}
                  airportValue={airportValue}
                />
                <ToAddress
                  setToAddress={setToAddress}
                  airportValue={airportValue}
                />
                <Button
                  variant="contained"
                  onClick={getQuote}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={30} /> : "Get Quote Now"}
                </Button>
              </div>
              {!goBack ? (
                <p className={style.airportPicking} onClick={handleOpen}>
                  Pickup from an Airport? or Dropoff to an Airport?
                </p>
              ) : (
                // )
                <p
                  className={style.airportPicking}
                  onClick={() => {
                    setFormScreen(false);
                    setQuoteData([]);
                    setGoBack(false);
                  }}
                >
                  <KeyboardBackspaceIcon />
                  Back
                </p>
              )}
            </div>
            {formScreen ? (
              <BookingForm
                whereAddressData={whereAddressData}
                toAddressData={toAddressData}
                vehicleDetail={vehicleDetail}
                setAttributes={setAttributes}
                setPickupTime={setPickupTime}
                newLoading={newLoading}
                pickupTime={pickupTime}
                attributes={attributes}
                setSuccessScreen={setSuccessScreen}
                airportValue={airportValue}
                jobId={token}
              />
            ) : (
              <VehicleList
                quoteData={quoteData}
                empty={empty}
                setEmpty={setEmpty}
                setFormScreen={setFormScreen}
                setVehicleDetail={setVehicleDetail}
                setVehicleType={setVehicleType}
              />
            )}
          </div>
        ) : (
          <div className={style.successScreen}>
            <div className={style.successContainer}>
              <img src={successGif} alt="success-gif" />
              <p>
                Your ride has been successfully confirmed! You will receive an
                email and SMS shortly with your booking confirmation. Your rider
                will be on time to pick you up. Thank you for choosing our
                service!
              </p>
              <Button
                variant="outlined"
                onClick={() => {
                  setToken("");
                  setAttributes([]);
                  setWhereAddressData({});
                  setToAddressData({});
                  setSuccessScreen(false);
                  setQuoteData([]);
                  setFormScreen(false);

                  setGoBack(false);
                }}
              >
                Book more
              </Button>
            </div>
          </div>
        )}
      </section>
      {/* modal for asking picking from or droping off to airport */}
      {!goBack && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={styles}>
              <div className={style.airportModal}>
                <p onClick={handleClose}>
                  <CloseIcon />
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={logo}
                    alt="airport-bee-logo"
                    style={{ width: 150 }}
                  />
                </div>
                <p>Select the your option</p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={airportValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="taking"
                    control={<Radio></Radio>}
                    label={"Pickup from Airport?"}
                  ></FormControlLabel>
                  <FormControlLabel
                    value="droping"
                    control={<Radio />}
                    label="Dropoff To Airport?"
                  />
                  <FormControlLabel
                    value="location"
                    control={<Radio />}
                    label="Address to Address?"
                  />
                </RadioGroup>
                <Button
                  variant="contained"
                  color="success"
                  style={{ alignSelf: "end" }}
                  onClick={handleClose}
                >
                  Proceed
                  <ArrowRightAltIcon />
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
}
// props validations
WhereFromAddress.propTypes = {
  setWhereAddress: PropTypes.func,
  airportValue: PropTypes.string,
};
ToAddress.propTypes = {
  setToAddress: PropTypes.func,
  airportValue: PropTypes.string,
};
