import style from "../../styles/Home.module.scss";
import paypal from "../../assets/paypal-logo.png";
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <section className={style.hero}>
      <div className={style.heroContainer}>
        <h1>LONDON AIRPORT MINICABS FOR HEATHROW, GATWICK & STANSTED TRANSFERS</h1>
        <h2>RELIABLE MINICAB SERVICES FOR ON-TIME PICKUP & DROP-OFF AT MAJOR LONDON AIRPORTS</h2>
      </div>
      <div className={style.callToAction}>
        <span>
          <p>QUICK, EASY AND PAY WITH</p>
          <img src={paypal} alt="paypal" />
        </span>
        <Link to="/booknow">
          <button className={"actionBtn"}>Book Now</button>
        </Link>
      </div>
    </section>
  );
}
