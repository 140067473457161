import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageDescription = ({ description }) => {
  const location = useLocation();

  useEffect(() => {
    document.getElementsByTagName("META")[3].content = description;
  }, [location, description]);

  return null;
};

export default PageDescription;