import style from "../styles/jobs.module.scss";
import jobsBanner from "../assets/images/jobsBanner.webp";
import JobsContent from "../layouts/JobsContent";
export default function Jobs() {
  return (
    <section>
      <div
        className={style.bannerSection}
        style={{ backgroundImage: `url(${jobsBanner})` }}
      ></div>
      <section className={style.content + " container"}>
        <JobsContent />
        <div className={style.whySection}>
          <h4>REQUIREMENTS</h4>
          <ul className={style.whylist}>
            <li>
              <h5>DRIVER </h5>
              <span>- VALID PCO LICENSE</span>
            </li>
            <li>
              <h5>DRIVER </h5>
              <span>- VALID PCO LICENSED VEHICLE & LOG BOOK</span>
            </li>
            <li>
              <h5>DRIVER </h5>
              <span> - VALID PRIVATE HIRE INSURANCE & MOT</span>
            </li>
            <li>
              <h5>DRIVER </h5>
              <span>- VALID DRIVING LICENSE</span>
            </li>
            <li>
              <h5>STAFF</h5>
              <span>- HAVE THE RIGHT TO WORK IN THE UK</span>
            </li>
            <li>
              <h5>STAFF </h5>
              <span>- ARE OF A LEGAL AGE TO WORK</span>
            </li>
            <li>
              <h5>STAFF </h5>
              <span>- GOOD WORK ETHIC</span>
            </li>
            <li>
              <h5>STAFF </h5>
              <span>- BASIC COMPUTER SKILLS</span>
            </li>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSffHrRv6LF7MgboN-9wzkQ0PW1hP8mu8ftrtk2mL5U_J1lNBg/viewform"
              className={style.bookNow}
            >
              Apply Now
            </a>
          </ul>
        </div>
      </section>
    </section>
  );
}
