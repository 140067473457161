import style from "../styles/terms.module.scss";
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function gatwickairporttransfers() {
  return (
    <section>
    
      <section className={" container"}>
        <section className={style.termsContent}>
        <br></br><br></br>
         <h1>Gatwick Airport Transfers - Book a Taxi to or from London</h1><br></br>
         <p>Arriving or departing from Gatwick Airport? Avoid the stress of public transport and book a reliable Gatwick airport transfer with Airport Bee. Whether you're flying for business or leisure, our professional drivers will ensure you get to your destination on time, every time. We offer fixed prices, 24/7 service, and luxury vehicles for a comfortable ride. From private transfers to group bookings, we've got you covered. Plus, we track your flight, so we'll adjust for any delays. Enjoy peace of mind knowing that your transfer is just a few clicks away.</p><br></br><br></br>
         <Link to="/booknow" className={style.bookNow}>
         Get an Instant Quote
            </Link>
            <br></br><br></br><br></br><br></br>
         <h2>Why Choose Our Gatwick Airport Transfer Service?</h2><br></br>
         <h3>Licensed and Professional Drivers</h3>
         <p>All of our drivers are fully licensed and have years of experience providing airport transfers. With extensive knowledge of London and the best routes to Gatwick, you'll always arrive on time.</p><br></br>
         <h3>Fixed Prices with No Hidden Charges</h3>
         <p>We offer transparent pricing with no surprises. The price you're quoted is the price you pay, no hidden fees or extra charges, even if your flight is delayed.</p><br></br>
         <h3>Free Cancellation</h3>
         <p>Plans change? No problem. We offer free cancellation up to 24 hours before your scheduled pickup time.</p><br></br>
         <h3>24/7 Customer Support</h3>
         <p>Our team is available around the clock to assist you, whether you're making a booking or need help on the day of your transfer.</p><br></br>
         <h3>Luxury Vehicles Available</h3>
         <p>We offer a fleet of modern and luxury vehicles, ensuring that you travel in comfort and style. Choose from standard taxis to executive cars.</p>
         <br></br><br></br>
        <h2>How to Book a Gatwick Airport Transfer</h2><br></br>
        <p>Booking your Gatwick transfer with Airport Bee is quick and easy. Just follow these simple steps:</p><br></br>
        <p>Step 1: Get a Quote</p><br></br>
        <p>Enter your pickup and drop-off locations, and select your preferred vehicle to receive an instant quote.</p><br></br>
        <p>Step 2: Confirm Your Booking</p><br></br>
        <p>Once you're happy with the quote, confirm your booking. You'll receive a confirmation email with all the details.</p><br></br>
        <p>Step 3: Meet Your Driver</p><br></br>
        <p>On the day of your transfer, your driver will track your flight to ensure they're ready to meet you on time at the designated pickup point.</p><br></br><br></br>
        <Link to="/booknow" className={style.bookNow}>
              Book Now
            </Link><br></br><br></br><br></br><br></br>
        <h2>Taxi Services to and from Gatwick Airport</h2><br></br>
        <p>Our Gatwick Airport transfer services cover all areas in and around London. Whether you're traveling from the city center or any surrounding areas, we ensure a smooth journey directly to your terminal.</p><br></br>
        <h3>Airport Pickup Instructions</h3>
        <p>Upon landing, your driver will track your flight to ensure punctuality. After you collect your luggage, head to the designated pickup point at Gatwick, where your driver will be waiting to assist you.</p><br></br>
        <h3>Fleet of Vehicles Available</h3>
        <p>Choose from a range of vehicles to suit your needs, from standard to executive cars, MPVs, and minivans for group travel. All vehicles are clean, comfortable, and equipped with modern amenities.</p><br></br>
        <h3>Real-time Flight Tracking</h3>
        <p>Our drivers monitor your flight status in real-time, so you don't have to worry about delays. We adjust our pickup times based on your actual arrival time, ensuring no unnecessary waiting fees.</p><br></br>
        <br></br><br></br>
        <h2>Fleet Options for Your Gatwick Transfer</h2><br></br>
        <p>We offer a wide range of vehicle options to cater to individual travelers, families, and large groups. Whether you need a standard saloon car for a quick ride or an executive vehicle for a business trip, we've got you covered.</p><br></br>
        <h3>Standard Cars:</h3>
        <p>Ideal for solo travelers or couples looking for an affordable, comfortable ride.</p><br></br>
        <h3>Executive Cars:</h3>
        <p>For those who prefer a little extra comfort and luxury, our executive cars offer a premium experience.</p><br></br>
        <h3>Minivans and Group Transfers:</h3>
        <p>Traveling with family or friends? Our spacious minivans can accommodate up to 8 passengers and plenty of luggage.</p><br></br><br></br><br></br>

         <h3>FAQs for Gatwick Airport Transfers</h3><br></br>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content" id="panel1-header">
            <Typography>How early should I book my Gatwick Airport transfer?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                We recommend booking your transfer as early as possible to secure the best price and availability, especially during peak travel seasons.
                </Typography>
            </AccordionDetails>
        </Accordion>
        
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content" id="panel2-header">
            <Typography>What happens if my flight is delayed?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                No need to worry! We track all flights in real-time, and your driver will adjust their schedule accordingly. There are no additional charges for flight delays.
                </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content" id="panel3-header">
            <Typography>Can I cancel my booking?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                Yes, you can cancel your booking free of charge up to 24 hours before your scheduled pickup time.
                </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content" id="panel3-header">
            <Typography>What is the meeting point at Gatwick Airport?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                After you've collected your luggage, follow the signs for "M & S " in the arrivals hall area where your driver will be waiting with a name sign.
                </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content" id="panel3-header">
            <Typography>Are your prices fixed?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                Yes, our prices are fixed with no hidden fees, even if your flight is delayed.
                </Typography>
            </AccordionDetails>
        </Accordion>

        </section>
      
      </section>
    </section>
  );
}
