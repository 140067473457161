import style from "../styles/privacyPolicies.module.scss";
export default function TermsContent() {
  return (
    <section className={style.termsContent}>
      <ul>
        <li>
          <h3>We respect your privacy</h3>
          <p>
            Cheetah Limited (CRN 02005174) of 148 Manor Park Road, London, NW10 4JR is the data controller for the purpose of the Data Protection Act 1998. Our Privacy policy will explain how personal
            data we collect is processed,stored and used. We are happy to hear your thoughts and views – so please take time out to read and share your feedback.
          </p>
        </li>
        <li>
          <h3>User Data Collection and Handling</h3>
          <p>
            We have a legal requirement to process your data in order to satisfy
            TFL regulations and ensure accuracy in the booking process.
          </p>
          <p>Cheetah Limited collects the following user data:</p>
          <ul>
            <li>
              data provided by users - for example, data given in the process of
              app account registration.
            </li>
            <li>
              data created by users in the process of using app services, like
              geopositioning, data about app operation and data about{` user's`}
              device.
            </li>
            <li>
              data gathered from other sources - like business partners,
              financial services providers and state authorities.
            </li>
          </ul>
          <h4>1.Data provided by users</h4>
          <ul>
            <li>
              user profile data, such as: first name, last name, email, mobile
              number, physical address, payment and bank account data, driving
              license data, user vehicle data, user photos. User photo needs to
              be provided in order to meet requirements of TFL regulations.
            </li>
            <li>
              user profile data, such as: first name, last name, email, mobile
              number, physical address, payment and bank account data, driving
              license data, user vehicle data, user photos. In some cases it is
              required by the law to provide driver photo.
            </li>
            <li>
              checking documents and verifying {`driver's`} identity might require
              checking licenses, photos, user name and last name.
            </li>
            <li>
              some data can be provided when users turn to technical support,
              this might include bank details, user device information, user app
              information, recordings of telephone calls in case of a user calls
              support, trip data (date and time, physical addresses, flight
              information).
            </li>
          </ul>
          <h4>2.Data created by users in the process of using app services</h4>
          <ul>
            <li>
              Geopositioning data. We track geoposition of drivers and
              passengers in order to organise a ride, provide maximum comfort
              and service level, provide safety and identify and prevent any
              fraud activity. Geopositioning is collected from mobile devices
              and only if users allow. Users can disable geopositioning, but
              this may result in poor service.
            </li>
            <li>
              Transaction data. We collect data on transactions made due to
              using our services, this includes type of transaction, amount,
              date and time of transation, card holder name.
            </li>
            <li>
              App usage data. We gather data on how users interact with our app
              and services. This data includes data and time of logging in and
              logging out, app functionality, app failures and system data
              needed to improve app quality and performance.
            </li>
            <li>
              Device data. We may collect data about the device which is used
              for access to our services, such as device model, IP address,
              unique device id, geo data and connection quality data.
            </li>
          </ul>
          <h4>3.Data collected from other sources</h4>
          <ul>
            <li>
              This is data from business partners which provide additional
              services: payment providers, apps and web-sites which use our API
              or which API is used by our app.
            </li>
            <li>
              This is data from business partners, such as travel data,
              including information about passengers or drivers.
            </li>
            <li>State authorities data.</li>
          </ul>
          <p>
            All Transaction Details are recorded to assist with future bookings
            with us and for compliance (TFL). These details aid speed the
            booking process working.
          </p>
        </li>
        <li>
          <h3>How Data is Used</h3>
          <p>Personal user data is used for:</p>
          <ul>
            <li>service providing.</li>
            <li>security providing.</li>
            <li>customer support.</li>
            <li>research and development.</li>
            <li>sending non-marketing messages.</li>
            <li>fulfilling legislation requirements and court orders.</li>
            <li>business process automation.</li>
          </ul>
          <h4>1.Service Providing</h4>
          <p>
            Cheetah Limited uses data for proving, personification, support and
            improving of its services. Such as:
          </p>
          <ul>
            <li>Creating accounts and changing its data.</li>
            <li>
              Providing riding service (geopositioning data for service
              improvement), data exchange (calculation of arriving time,
              security providing, routes calculation, linking to flight
              information, tracking and sending information about a ride).
            </li>
            <li>Payment processing and other financial data.</li>
            <li>
              Performing operations which are neededin order to improve service
              and customer support, testing, analysis and also monitoring of
              services used.
            </li>
          </ul>
          <h4>2.Security Providing</h4>
          <p>
            Cheetah Limited uses data for providing security and protection of
            users and also security for our services. This includes:
          </p>
          <ul>
            <li>
              in order to comply with government requirements we may ask to
              provide driver photo and also passing it to passengers.
            </li>
            <li>
              using data from drivers and passengers devices in order to
              evaluate journey safety and reminding to subcontractors to make
              their driving safer.
            </li>
            <li>
              using data from drivers and passengers devices in order to
              identify and prevent any fraud activity. For example, we detect
              fraud accounts and cases when our services are used for illegal
              purposes, and prevent unauthorised access to our users accounts.
            </li>
            <li>
              using rating system for evaluation of driver and passengers in
              order to prevent colliding users with potentially high risk of
              conflict.
            </li>
            <li>
              sending information about serious violations to third parties such
              as police authorities - in accordance to legislation requirements.
            </li>
            <p>
              To prevent unauthorized access, maintain data accuracy, and ensure
              the correct use of information, we have put in place appropriate
              physical, electronic, and managerial procedures to safeguard and
              secure the information we collect online. Booking online at
              Cheetah Cars is completely secure. We even transmit your booking
              information to us using 256 bit SSL encryption (not just when it
              comes to payment part of the process). Your data is stored via our
              Cordic Technology Ltd (See Privacy Policy{" "}
              <a href="https://www.cordic.com/privacy-policy/">
              https://www.cordic.com/privacy-policy/
              </a>
              ) on a dedicated cloud based server 
            </p>
          </ul>
          <h4>3.Client Support</h4>
          <p>
            Cheetah Limited uses personal data, application data, systems and
            devices in order to provide technical support and improve quality of
            its services.
          </p>
          <h4>4.Research and Development</h4>
          <p>
            Cheetah Limited uses personal data in order to perform testing,
            research, analysis, development and improvement of its services.
            This helps us to make our services better and more secure and
            develop new functionality.
          </p>
          <h4>5.Sending Non-Marketing Messages</h4>
          <p>
            We can use personal data in order to inform our users about changing
            of terms and rules, and also for sending non-marketing messages
            which are needed to provide services.
          </p>
          <h4>6.Fulfilling Legislation Requirements and Court orders</h4>
          <p>
            We can use personal data in order to settle litigations and court
            orders associated with using of Cheetah Limited services, fulfilling
            law orders, in cases when data is required by public authorities,
            including police.
          </p>
          <h4>7.Automation of Business Processes</h4>
          <p>
            Cheetah Limited uses personal data for automation of business
            processes, such as:
          </p>
          <ul>
            <li>
              finding eligible drivers. Drivers can be found on the basis or
              availability, nearest position and other factors, as well as on
              the basis of statisticaluser data.
            </li>
            <li>
              Users who are suspected in fraud activity or any other activity
              which may cause harm to Cheetah Limited. In cases when users
              provide false information or fake license details this may lead to
              disabling user account after a specialist performs their checks.
            </li>
            <li>
              using of{` driver's`} data (their geoposition, rating) and {`passenger's`}
              data (departure location, rating) in order to eliminate collisions
              of users with high risk of conflict.
            </li>
          </ul>
          <h3>Using cookies</h3>
          <p>Cheetah Limited uses Cookie files in order to:</p>
          <ul>
            <li>perform user authentication.</li>
            <li>save user settings.</li>
            <li>keep history of rides and other historical user data.</li>
            <li>
              display a language in web-site interface according to your
              geographical position.
            </li>
          </ul>
          <p>
            Cookie is a small piece of data which is sent by a web-service and
            stored on a user device (i.e. personal computer, mobile phone,
            tablet).
          </p>
          <p>
            We use a number of different cookies on our site. If you do not know
            what cookies are, or how to control or delete them, then we
            recommend you visit{" "}
            <a href=" https://www.aboutcookies.org">
              {" "}
              https://www.aboutcookies.org
            </a>{" "}
            for detailed guidance.
          </p>
          <p>
            Google Analytics: We use Google Analytics to collect information
            about visitor behaviour on our website. Google Analytics stores
            information about what pages you visit, how long you are on the
            site, how you got here and what you click on. This Analytics data is
            collected via a JavaScript tag in the pages of our site and is not
            tied to personally identifiable information.We therefore do not
            collect or store your personal information (e.g. your name or
            address) so this information cannot be used to identify who you are.
            You can find out more about Google’s position on privacy as regards
            its analytics service at{" "}
            <a href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
            <br />
            <br />
            Google AdWords: We use Google AdWords cookies so we are able to see
            which pages helped lead to bookings on our website. This allows us
            to make better use of our paid search budget. We also use the Google
            AdWords remarketing service to advertise on third party websites
            (including Google) to previous visitors to our site. It could mean
            that we advertise to previous visitors who haven’t completed a task
            on our site, for example using the contact form to make an enquiry.
            This could be in the form of an advertisement on the Google search
            results page, or a site in the Google Display Network. Third-party
            vendors, including Google, use cookies to serve ads based on
            someone’s past visits to Cheetah Cars. Of course, any data collected
            will be used in accordance with our own privacy policy and Google’s
            privacy policy. You can set preferences for how Google advertises to
            you using the Google Ad Preferences page.
            <br />
            <br />
            Booking/Session Cookie: We use a session cookie to remember what you
            have told us during the booking process. We deem these cookies
            strictly necessary to the working of the website. If these are
            disabled then various functionality on the site will be broken and
            bookings will be unreliable.
            <br />
            <br />
            Your IP Address, device information, browser type may be recorded –
            we cannot establish the individual but merely used as a form
            statistics and patterns – Our online site and mobile application may
            collect Cookies to distinguish customers – the customer can control
            this through their browser settings and preferences. This element
            allows customers to gain better experience when browsing our site.
            The Mobile App uses location tracking – this enables us to locate
            you as a customer and offer services – this feature can also be
            disabled but could affect accuracy in the offering.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>Transfer and Disclosure of User Data</h3>
          <p>
            Cheetah Limited performs data transfer to other users or provides
            data on demand in cases where it falls under law requirements or
            when it is required in order to satisfy claims or settle dispute
            resolutions. Cheetah Limited can transfer user data in the following
            cases:
            <br />
            <br />
            1.To Other Users:
            <br />
            1.In order to perform a ride we may give to a driver your name,
            email, telephone, information about pickup location and destination
            location.
            <br />
            2.In order to perform a ride we may give to a passenger your name,
            photograph, license number, vehicle brand and model and vehicle
            registration number.
            <br />
            <br />
            2.To Suppliers and Business Partners:
            <br />
            1.Cheetah Limitedmay transfer ride details to its partners via API
            integrations.
            <br />
            2.We also transfer data to credit card transaction providers.
            <br />
            <br />
            3.Providing Data Under Legislation Grounds or in Dispute Cases.
            <br />
            Cheetah Limitedmay disclose personal data in cases when legislation
            requires to do so, in court disputes, or when public authorities,
            including police, require this.
            <br />
            4.Cases When Users Allow Sending Data.
            <br />
            Cheetah Limitedmay send data to third parties in cases that go
            beyond this Agreement, if we have informed you about our intention
            to send data and you have allowed this.
          </p>
        </li>
        <li>
          <h3>Storage and Extermination of Data</h3>
          <p>
            Cheetah Limited stores personal data up to the point where goals of
            its processing which are described in this Agreement are met.
            <br />
            <br />
            Users can any time send a request to delete their account. However
            Cheetah Limited may store personal data even after performing a user
            request in order to meet legislative requirements.
            <br />
            <br />
            To delete your account, send a request to the
            office@cheetahcars.co.uk.
          </p>
        </li>
        <li>
          <h3>Data Access and Data Collection Refusal</h3>
          <p>
            Cheetah Limited gives possibility to users to review settings of
            data collection and manage them through the following:
            <br />
            <br />
            {`Device permissions. You can manage app permissions in Device
            settings -> Apps -> Permissions -> Choose permissions.`}
            <br />
            <br />
            1.Confidentiality Settings
            <br />
            <br />
            {`Users may set and manage settings of collection and transfering geopositioning data and getting notifications in the "Settings" > "Applications" > Name of the Airport Bee - London Minicabs"Rights" and "Notifications".`}
            2.Geopositioning data
            <br />
            <br />
            Users may allow or not allow collecting geopositioning of their
            mobile devices.
            <br />
            <br />
            3.Permission to camera
            <br />
            <br />
            The camera is used to scan credit card details at the time of
            booking payment. The payment process is provided through the
            financial service {`provider's`} SDK. <br />
            4.Getting data on telephone status
            <br />
            <br />
            Users can allow/not allow access to telephone functions on their
            device. If this function is disabled, this will result in failure of
            sending requests from the Cheetah Cars app.
            <br />
            <br />
            5.Enable/ disable notifications
            <br />
            <br />
            Users can enable/ disable notifications about ride status changes or
            account actions. Disabling notifications may result in lower quality
            of user experience with the Cheetah Cars app.
            <br />
            <br />
            Disabling Marketing Messages
            <br />
            <br />
            You can turn off the sending of marketing messages by contacting
            office@cheetahcars.co.uk subject title Data Control Team.
          </p>
        </li>
        <li>
          <h3>Changes in Confidentiality Agreement</h3>
          <p>
            Cheetah Limited has a right to make changes to this Confidentiality
            Agreement. If changes are significant, we will notify you via
            Cheetah Cars app or in any other available way, for example, by
            email.
            <br />
            <br /> Use of our services after an update constitutes consent to
            the updated notice to the extent permitted by law.
          </p>
        </li>
        <li>
          <h3>Card Payments</h3>
          <p>
            We are responsible for card payments stored or transmitted. There
            are two categories for payments which are defined below: <br />
            <br />
            a) Customer not present - Online/App/Telephone <br />
            <br />
            The payments part of our booking process is handled by Stripe . You
            should refer to the Stripe privacy policy to learn more.
            <a href="https://stripe.com/gb/privacy">
            https://stripe.com/gb/privacy
            </a>
            <br />
            <br />
            All Debit/Credit Card transactions use a Pre Authorisation process –
            and if any pre authorisation is unsuccessful we have the right not
            to offer the service or offer a cash option if available.
            <br />
            <br /> b) Customer present - Face to Face <br />
            <br />
            The payments part of our booking process is handled by SumUp. You
            should refer to the SumUp privacy policy to learn more.{" "}
            <a href="https://sumup.co.uk/privacy">
              https://sumup.co.uk/privacy
            </a>
          </p>
        </li>
        <li>
          <h3>How to Contact Us</h3>
          <p>
            Should you have further questions you can reach out to
            office@cheetahcars.co.uk subject title Data Control Team. Registered
            Address - 148 Manor Park Road, London, NW10 4JR 
            <br />
            <br />
            Revised on 05-09-2024
          </p>
        </li>
      </ul>
    </section>
  );
}
