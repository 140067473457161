import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import BookNow from "./pages/BookNow";
import Layout from "./pages/Layout.jsx";
import AboutUs from "./pages/about.jsx";
import HeathrowAirport from "./pages/heathrowAirport.jsx";
import LutonAirport from "./pages/lutonAirport.jsx";
import GatwickAirport from "./pages/gatwickairporttransfers.jsx"
import US from "./pages/us.jsx";
import Terms from "./pages/terms.jsx";
import PrivacyPolices from "./pages/privacyPolices.jsx";
import Jobs from "./pages/jobs.jsx";
import ManageBooking from "./pages/manageBooking.jsx";
import Blogs from "./pages/blogs.jsx";
import Blog from "./pages/blogPage.jsx";
import PageTitle from "./PageTitle";
import PageDescription from "./PageDescription";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
       
          {/* <Route path="/booknow" element={<BookNow />} />
          <Route path="/" index element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/jobs" element={<Jobs />}></Route>
          <Route path="/managebooking" element={<ManageBooking />}></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/privacy-policies" element={<PrivacyPolices />}></Route> */}
          <Route path="/blogs/:slug" element={<Blog />}></Route>
          
          <Route
            path="/"
            index
            element={
              <>
                <PageTitle title="London Airport Minicabs - Heathrow, Gatwick & Stansted Transfers" />
                <PageDescription description="Arrive on time with Airport Bee! Offering minicab pickups and drop-offs at Heathrow, Gatwick, Stansted, and all major London airports.Professional, on-time, and competitively priced service."/>
                <Home />
              </>
            }
          />
          <Route
            path="/booknow"
            element={
              <>
                <PageTitle title="Fast & Affordable Airport Transfers - Book Minicabs for Heathrow, Gatwick, Stansted" />
                <PageDescription description="Book your airport transfer in seconds with Airport Bee. Get fast, reliable minicabs for Heathrow, Gatwick, and Stansted. Easy online booking, competitive fares, and prompt service. Reserve your ride now!"/>
                <BookNow />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <PageTitle title="About Airport Bee - London's Trusted Airport Transfer Service" />
                <PageDescription description="Discover Airport Bee, your reliable partner for airport transfers across London. Offering professional minicab services to Heathrow, Gatwick, Stansted, and beyond"/>
                <AboutUs />
              </>
            }
          ></Route>
          <Route
            path="/terms"
            element={
              <>
                <PageTitle title="Airport Bee | Terms" />
                <Terms />
              </>
            }
          ></Route>
          <Route
            path="/jobs"
            element={
              <>
                <PageTitle title="Airport Bee | Jobs" />
                <Jobs />
              </>
            }
          ></Route>
          <Route
            path="/managebooking"
            element={
              <>
                <PageTitle title="Airport Bee | Manage Bookings" />
                <ManageBooking />
              </>
            }
          ></Route>
          <Route
            path="/privacy-policies"
            element={
              <>
                <PageTitle title="Airport Bee | Privacy Policy" />
                <PrivacyPolices />
              </>
            }
          ></Route>
          <Route
            path="/heathrow-airport-transfers"
            element={
              <>
                <PageTitle title="Airport Bee | Heathrow Airport Transfers" />
                <PageDescription description="Looking for a Heathrow Airport Transfer? We specialise in airport taxi transfers to and from Luton Airport for both individuals and groups."/>
                <HeathrowAirport />
              </>
            }
          ></Route>
          <Route
            path="/luton-airport-transfers"
            element={
              <>
                <PageTitle title="Airport Bee | Luton Airport Transfers" />
                <PageDescription description="Looking for a Luton Airport Transfer? We specialise in airport taxi transfers to and from Luton Airport for both individuals and groups."/>
                <LutonAirport />
              </>
            }
          ></Route>
           <Route
            path="/gatwick-airport-transfers"
            element={
              <>
                <PageTitle title="Gatwick Airport Transfers | Reliable Taxi to/from Gatwick | Airport Bee" />
                <PageDescription description="Book reliable Gatwick airport transfers with Airport Bee. Affordable and prompt taxi service from London to Gatwick, ensuring a hassle-free ride. Free cancellation and 24/7 support."/>
                <GatwickAirport />
              </>
            }
          ></Route>
          <Route
            path="/us"
            element={
              <>
                <PageTitle title="Reliable London Airport Transfers for USA Travelers - Heathrow & Gatwick" />
                <PageDescription description="Flying from the USA to London? Airport Bee offers easy, reliable Airport transfers for USA visitors to Heathrow, Gatwick & other major airports. Pre-book your ride today!"/>
                <US />
              </>
            }
          ></Route>
          <Route
            path="/blogs"
            element={
              <>
                <PageTitle title="London Travel & Airport Transfers Blog - Tips, Guides & More" />
                <PageDescription description="Find everything you need to know about airport transfers in London. From Heathrow and Gatwick to Stansted, our blog offers tips, pricing advice, and the latest travel trends for airport transfers."/>
                <Blogs />
              </>
            }
          ></Route>
        </Route>
        <Route path="/*" element={<Layout />}>
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
