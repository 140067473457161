import style from "../styles/terms.module.scss";
// import { aboutUs } from "../Lib/aboutUs";
import { Link } from "react-router-dom";
import termsBanner from "../assets/images/terms.webp";
import TermsContent from "../layouts/TermsContent";
export default function Terms() {
  return (
    <section>
      <div
        className={style.bannerSection}
        style={{ backgroundImage: `url(${termsBanner})` }}
      ></div>
      <section className={style.content + " container"}>
        <TermsContent />
        <div className={style.whySection}>
          <h4>FEEDBACK AND COMPLAINTS</h4>
          <ul className={style.whylist}>
            <li>
              <h3>Feedback</h3>
              <p>
                Our management team consistently monitor feedback from customers
                and accounts. If you have any feedback for our drivers or staff,
                good or bad, please share it with us at feedback@airportbee.com
              </p>
            </li>
            <li>
              <h3>Complaints</h3>
              <p>
                We take complaints very seriously. If the event you have a
                complaint about a driver or about a recent booking, please reach
                out to feedback@airportbee.com where our complaint handler will
                deal with your issue promptly
              </p>
            </li>
            <li>
              <h3>We’re Always Here</h3>
              <p>
                Our office is open 24 hours a day - even on public holidays.
                This means you can count on us for your questions whatever the
                time of day or year.
              </p>
            </li>
            <Link to="/booknow" className={style.bookNow}>
              Book Now
            </Link>
          </ul>
        </div>
      </section>
    </section>
  );
}
