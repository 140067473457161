import style from "../styles/terms.module.scss";
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function lutonAirport() {
  return (
    <section>
    
      <section className={style.content + " container"}>
        <section className={style.termsContent}>
         
         <h1>Luton Airport Transfers with Airport Bee</h1><br></br>
         <p>London Luton Airport (LLA) is one of the UK's busiest airports, carrying over 16.2 million passengers in 2023. The airport is operated by a consortium, of which the majority shareholder is AENA, the world's largest airport operator, and InfraBridge, a leading infrastructure investment manager. Airlines include easyJet, Wizz Air, Ryanair, Tui, FlyOne, Sun Express and El Al.</p><br></br>
         <p>In 2024, LLA was named European Airport of the Year (10-25 million passengers) by ACI EUROPE. This followed being named as the UK's best airport by readers of the prestigious Condé Nast Traveller magazine in 2023.</p><br></br>
         
         <h3>Significance of Efficient Airport Transfers at Luton</h3><br></br>
         <p>Efficient airport transfers are vital for travellers arriving at or departing from Luton Airport. London Luton Airport (LLA) has opened a new Express Drop Off zone to provide an even more convenient option for passengers looking to be dropped off directly in front of the terminal entrance. With Airport Bee from the moment you step out of your front door, drivers will drop you off at your terminal in Luton airport, well in advance of your flight so you have enough time to check-in</p><br></br>
                  

         <h3>Advantages of Airport Bee for Luton Airport Transfers</h3><br></br>
         <p>Airport Bee, a renowned Airport Transfer company, offers a host of advantages to travellers at Luton Airport:<br></br><br></br>
            <b>Prompt and Punctual Service:</b> AWe work with punctual, reliable, professional drivers and have a 24/7 customer service team, ready to help you with any questions you may have – your holiday starts from the moment you are in our car.<br></br><br></br>
            <b>Diverse Fleet of Vehicles:</b> Airport Bee boasts a diverse fleet of vehicles, ranging from saloon to luxury executive and spacious MPVs, catering to the preferences and needs of all travellers.<br></br><br></br>            
            <b>24/7 Availability:</b> Airport Bee operates round-the-clock, accommodating early morning or late-night flights and last-minute bookings.</p><br></br>

        <h3>The Convenience of Pre-Booking Luton Airport Transfers.</h3><br></br>
        <p>Pre-booking Luton Airport transfers with Airport Bee offers numerous benefits:<br></br><br></br>
            <b>Efficient Planning:</b> Have a holiday to look forward to? Why not get a head start by arranging your journey now? You can book your car up to twelve months in advance. Remember to book an airport pickup with Aiport Bee for your return journey at the same time – That's one less thing to worry about while you are on holiday enjoying the sun.<br></br><br></br>
            <b>Transparent Pricing:</b> Pre-booking enables passengers to receive fixed and transparent pricing, avoiding any surge prices or hidden charges.<br></br><br></br>
            <b>Real-Time Flight Monitoring:</b> Airport Bee utilises cutting-edge technology to monitor flight schedules in real-time, ensuring their drivers are promptly informed of any changes or delays.</p><br></br>
            <p>Further information on London Luton Airport can be found here: <a href="https://www.london-luton.co.uk/"> https://www.london-luton.co.uk/</a></p><br></br><br></br>


        

        <h3>Frequently Asked Questions</h3><br></br>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content" id="panel1-header">
            <Typography>Can I book a Luton Airport transfer at short notice?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Yes, Airport Bee offers 24/7 services and accommodates last-minute bookings to cater to your travel needs.
                </Typography>
            </AccordionDetails>
        </Accordion>
        
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content" id="panel2-header">
            <Typography>Are child seats available for families travelling with young children?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Absolutely! Airport Bee provides child seats upon request to ensure the safety and comfort of young passengers.
                </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content" id="panel3-header">
            <Typography>Can I request a specific type of vehicle for my transfer?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                Yes, Airport Bee offers a diverse fleet of vehicles, and passengers can request a specific type of vehicle based on their preferences and group size.
                </Typography>
            </AccordionDetails>
        </Accordion>

        </section>
        <div className={style.whySection}>
          <h4>WHY BOOK WITH US?</h4>
          <ul className={style.whylist}>
            <li>
              <h3>Reliable and Safe</h3>
              <p>
                Our drivers are insured and PCO certified - just like you would
                expect. However, we also spend time training our drivers. How
                many other private hire companies do that?
              </p>
            </li>
            <li>
              <h3>No hidden fees</h3>
              <p>
                You pay for what you use. With different payment options,
                including cash - using a Airport Bee driver even helps your
                wallet!
              </p>
            </li>
            <li>
              <h3>We're Always Here</h3>
              <p>
                Our office is open 24 hours a day - even on public holidays.
                This means you can count on us for your questions whatever the
                time of day or year.
              </p>
            </li>
            <Link to="/booknow" className={style.bookNow}>
              Book Now
            </Link>
          </ul>
        </div>
      </section>
    </section>
  );
}
