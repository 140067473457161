// dependencies

import style from "../../styles/BookNow.module.scss";
import PropTypes from "prop-types";
import { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import XMLParser from "react-xml-parser";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import paypal from "../../assets/paypal-logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import Paypal from "./Paypal";
import { BASE_URL } from "../../config";
// import Modal from "@mui/material";
// toggle component
function PickupComponent({
  setPickup,
  pickup,
  time,
  date,
  setTime,
  setDate,
  setPickupTime,
  airportValue,
  airportPickupTime,
  setAirportPickupTime,
  seatNum,
  setSeatNum,
}) {
  // console.log(airportValue);
  return (
    <div className={style.pickingOption}>
      <p style={{ marginBottom: "1rem" }}>
        {airportValue === "location"
          ? "When should we pick you up?"
          : airportValue === "taking"
          ? "WHAT IS YOUR FLIGHT ARRIVAL DATE AND TIME?"
          : "Date & Time"}
      </p>
      <div className={style.options}>
        {airportValue === "location" && (
          <ToggleButtonGroup
            color="primary"
            value={pickup}
            exclusive
            onChange={(e) => setPickup(e.target.value)}
            aria-label="Platform"
          >
            <ToggleButton value="asap">As Early As Possible</ToggleButton>
            <ToggleButton value="later">LATER</ToggleButton>
          </ToggleButtonGroup>
        )}
        {pickup === "later" && (
          <div className={style.timings}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Pickup Date"
                value={date}
                format="DD-MM-YYYY"
                onChange={(newValue) => {
                  setDate(newValue);
                  setPickupTime(
                    moment(newValue).format("YYYY-MM-DD") +
                      "T" +
                      moment(time).format("HH:mm:ss")
                  );
                }}
                minDate={moment()}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Pickup Time"
                value={time}
                onChange={(newValue) => {
                  setTime(newValue);
                  setPickupTime(
                    moment(date).format("YYYY-MM-DD") +
                      "T" +
                      moment(newValue).format("HH:mm:ss")
                  );
                }}
                ampm={false}
              />
            </LocalizationProvider>
          </div>
        )}
        {/* picking details from aiport  */}
      </div>
      {airportValue !== "location" && airportValue !== "droping" && (
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          <p style={{ marginTop: "1rem" }}>PICK-UP TIME (AFTER FLIGHT LANDS)</p>
          <div className={style.options} style={{ margin: "1rem 0" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Pick up time after flight lands
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={airportPickupTime}
                label="Pick up time after flight lands"
                onChange={(e) => setAirportPickupTime(e.target.value)}
              >
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={40} selected>
                  40 Minutes
                </MenuItem>
                <MenuItem value={50}>50 Minutes</MenuItem>
                <MenuItem value={60}>60 Minutes</MenuItem>
                <MenuItem value={75}>75 Minutes</MenuItem>
                <MenuItem value={90}>90 Minutes</MenuItem>
                <MenuItem value={120}>120 Minutes</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Flight Number"
              variant="outlined"
              required
              value={seatNum}
              onChange={(e) => setSeatNum(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
// booking form component
export default function BookingForm({
  whereAddressData,
  toAddressData,
  vehicleDetail,
  newLoading,
  setPickupTime,
  setAttributes,
  pickupTime,
  attributes,
  setSuccessScreen,
  airportValue,
  jobId,
}) {
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment().add(30, "minutes"));
  const [phoneValue, setPhoneValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pickup, setPickup] = useState("later");
  const [airportPickupTime, setAirportPickupTime] = useState(40);
  const [seatNum, setSeatNum] = useState("");
  const [driverNotes, setDriverNotes] = useState("");
  const [payment, setPayment] = useState("API-ABPAYPAL");
  const [showPaypal, setShowPaypal] = useState(false);
  // console.log(phoneValue.slice(3));
  // The rest of your code remains unchanged...

  // Calling the function with dynamicData
  // console.log(objectToXml({ JobRequest: dynamicData }));

  // posting job request and api,
  const handleJobRequest = (payPalRefId = "") => {
    // Define dynamic data
    const dynamicData = {
      SourceSystem: "CH-CHEETAH",
      SourcePassword: "m=,8~u+k?4g~ha4f3e2a",
      SourceJobID: new Date().getTime(),
      Lifetime: "60",
      pickuptime: pickupTime
        ? pickupTime
        : `${moment(date).format("YYYY-MM-DD")}T${moment(time).format(
            "HH:mm:ss"
          )}`,
      TargetSystem: "one2onecars",
      SourceAccount: payment === "cash" ? "API-CHEETAHCASH" : "API-ABPAYPAL",
      BookerName: name,
      BookerPhone:
        phoneValue.substring(0, 3) === "+44"
          ? "0" + phoneValue.slice(3)
          : "00" + phoneValue.slice(1),
      BookerEmail: email,
      VehicleType: vehicleDetail?.vehicleType,
      FlightDetails: seatNum ? seatNum : "Not Available",
      DriverNotes: `${
        airportValue === "taking"
          ? `Pick me up after ${airportPickupTime} minutes`
          : ""
      } ${driverNotes}`,
      StopList: [
        {
          Order: "1",
          Passenger: name,
          Address: `${seatNum ? seatNum : ""},
            ${whereAddressData?.formatted_address.join(",")}`,
          Postcode: whereAddressData?.postcode,
          ContactPhone:
            phoneValue.substring(0, 3) === "+44"
              ? "0" + phoneValue.slice(3)
              : "00" + phoneValue.slice(1),
          ContactOnBook: "Text",
          ContactOnAccept: "Text",
          ContactOnArrive: "Text",
          ContactOnComplete: "Text",
        },
        {
          Order: "2",
          Address: toAddressData.formatted_address.join(","),
          Postcode: toAddressData?.postcode,
        },
      ],
      AttributeList: attributes,
      ReferenceList: [
        {
          Order: 1,
          Value: payPalRefId,
        },
      ],
    };
    // Function to convert an object to an XML string
    const objectToXml = (obj) => {
      let xml = "";
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === "StopList") {
            xml += `<${key}>`;
            if (Array.isArray(obj[key])) {
              for (const item of obj[key]) {
                xml += `<Stop>`;
                xml += objectToXml(item);
                xml += `</Stop>`;
              }
            }
            xml += `</${key}>`;
          } else if (key === "AttributeList" && Array.isArray(obj[key])) {
            xml += `<${key}>`;
            for (const attribute of obj[key]) {
              xml += `<Attribute>${attribute}</Attribute>`;
            }
            xml += `</${key}>`;
          } else if (key === "ReferenceList" && Array.isArray(obj[key])) {
            xml += `<${key}>`;
            for (const reference of obj[key]) {
              xml += `<Reference>`;
              xml += objectToXml(reference);
              xml += `</Reference>`;
            }
            xml += `</${key}>`;
          } else if (key === "FlightDetails") {
            if (typeof obj[key] === "string") {
              xml += `<${key}><FlightCode>${obj[key]}</FlightCode></${key}>`;
            } else if (typeof obj[key] === "object") {
              xml += `<${key}>${objectToXml(obj[key])}</${key}>`;
            }
          } else if (typeof obj[key] === "object") {
            xml += `<${key}>${objectToXml(obj[key])}</${key}>`;
          } else {
            xml += `<${key}>${obj[key]}</${key}>`;
          }
        }
      }
      return xml;
    };
    // Construct the XML data
    const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
    <SOAPENV:Envelope xmlns:SOAPENV="http://www.w3.org/2003/05/soap-envelope"
                      xmlns:SOAPENC="http://www.w3.org/2003/05/soap-encoding"
                      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                      xmlns:xsd="http://www.w3.org/2001/XMLSchema">
        <SOAPENV:Body>
            ${objectToXml({ JobRequest: dynamicData })}
        </SOAPENV:Body>
      </SOAPENV:Envelope>`;

    // console.log(xmlData);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: BASE_URL + "/sendXML",
      headers: {
        "Content-Type": "application/xml",
      },
      data: { xml: xmlData, json: { ...dynamicData, app: "airportbee" } },
    };
    axios
      .request(config)
      // .post(BASE_URL + "/sendXML", { xmlData })
      .then((response) => {
        // console.log(response.data.response);
        const xml = new XMLParser().parseFromString(response.data.response);
        // console.log(xml);
        if (xml.children[0].children[0].children[0].value === "OK") {
          setSuccessScreen(true);
        }
        // Handle the SOAP response here
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <section className={"container " + style.bookingFormContainer}>
      <h2>Booking Details</h2>
      <div className={style.bookingDetail}>
        <form action="" className={style.bookingForm}>
          <PickupComponent
            setPickup={setPickup}
            pickup={pickup}
            time={time}
            setTime={setTime}
            date={date}
            setDate={setDate}
            setPickupTime={setPickupTime}
            airportValue={airportValue}
            airportPickupTime={airportPickupTime}
            setAirportPickupTime={setAirportPickupTime}
            seatNum={seatNum}
            setSeatNum={setSeatNum}
          />
          <div className={style.passengerDetail}>
            <p>Passenger Details</p>
            <div className={style.detailFields}>
              <TextField
                variant="outlined"
                label={"Passenger Name*"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value) {
                    e.target.classList.remove(style.errorClass);
                  } else {
                    e.target.classList.add(style.errorClass);
                  }
                }}
                onFocus={(e) => e.target.classList.remove(style.errorClass)}
              />
              <TextField
                variant="outlined"
                label={"Email*"}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value) {
                    e.target.classList.remove(style.errorClass);
                  } else {
                    e.target.classList.add(style.errorClass);
                  }
                }}
                onFocus={(e) => e.target.classList.remove(style.errorClass)}
              />
              <PhoneInput
                defaultCountry="GB"
                placeholder="Enter phone number*"
                value={phoneValue}
                onChange={setPhoneValue}
                rules={{ required: true }}
                className={style.phoneField}
                onBlur={(e) => {
                  if (e.target.value) {
                    e.target.classList.remove(style.errorClass);
                  } else {
                    e.target.classList.add(style.errorClass);
                  }
                }}
                onFocus={(e) => e.target.classList.remove(style.errorClass)}
              />
            </div>
          </div>
          <div className={style.passengerDetail}>
            <p>Extras</p>
            <div className={style.extraFields}>
              <FormGroup className={style.formFields}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="BABY SEAT"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowPaypal(false);
                      setAttributes((prev) => [...prev, "baby seat"]);
                    } else {
                      setShowPaypal(false);
                      setAttributes((prev) =>
                        prev.filter((itm) => itm !== "baby seat")
                      );
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="CHILD SEAT"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowPaypal(false);
                      setAttributes((prev) => [...prev, "Child seat"]);
                    } else {
                      setShowPaypal(false);
                      setAttributes((prev) =>
                        prev.filter((itm) => itm !== "Child seat")
                      );
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BOOSTER SEAT"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowPaypal(false);
                      setAttributes((prev) => [...prev, "booster seat"]);
                    } else {
                      setShowPaypal(false);
                      setAttributes((prev) =>
                        prev.filter((itm) => itm !== "booster seat")
                      );
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="MEET & GREET"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowPaypal(false);
                      setAttributes((prev) => [...prev, "meet+greet"]);
                    } else {
                      setShowPaypal(false);
                      setAttributes((prev) =>
                        prev.filter((itm) => itm !== "meet+greet")
                      );
                    }
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div className={style.passengerDetail}>
            <p>PAYMENT TYPE</p>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="paypal"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="paypal"
                control={<Radio></Radio>}
                label={<img src={paypal} alt="" />}
                onChange={(e) => {
                  setPayment(e.target.value);
                }}
              />
              <FormControlLabel
                value="cash"
                control={<Radio />}
                label="CASH"
                onChange={(e) => {
                  setShowPaypal(false);
                  setPayment(e.target.value);
                }}
              />
            </RadioGroup>
          </div>
          <div className={style.passengerDetail}>
            <p>Additional instructions for driver</p>
            <textarea
              placeholder="write something"
              className={style.textarea}
              rows={3}
              value={driverNotes}
              onChange={(e) => setDriverNotes(e.target.value)}
            />
          </div>
        </form>
      </div>
      {/* booking Summary */}
      <div className={style.bookingSummary}>
        <h3>Booking Summary</h3>
        <div className={style.summaryView}>
          <span className={style.summaryContent}>
            <h4>From</h4>
            <p>{whereAddressData?.formatted_address?.join(",")}</p>
          </span>
          <span className={style.summaryContent}>
            <h4>To</h4>
            <p>{toAddressData?.formatted_address?.join(",")}</p>
          </span>
          <span className={style.summaryContent}>
            <h4>Vehicle</h4>
            <p>{vehicleDetail?.vehicleType}</p>
          </span>
        </div>
        <h3>
          {newLoading ? (
            <CircularProgress size={"1rem"} />
          ) : (
            <span>Total £{vehicleDetail?.price.toFixed(2)}</span>
          )}
        </h3>
        <p style={{ padding: "1rem" }}>
          {`That's it! Please review all your details, our terms and confirm your
          booking below.`}
        </p>
        {name && phoneValue && vehicleDetail?.price && (
          <>
            {showPaypal || (
              <Button
                variant="contained"
                onClick={() => {
                  if (payment === "cash") {
                    handleJobRequest();
                  } else {
                    setShowPaypal(true);
                  }
                }}
              >
                Confirm Booking
              </Button>
            )}
            {showPaypal && (
              <div style={{ padding: "1rem" }}>
                <Paypal
                  handleJobRequest={handleJobRequest}
                  price={vehicleDetail.price}
                />
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}
// props validations
BookingForm.propTypes = {
  vehicleDetail: PropTypes.shape({
    price: PropTypes.number,
    vehicleType: PropTypes.string,
  }),
  whereAddressData: PropTypes.shape({
    formatted_address: PropTypes.string,
    postcode: PropTypes.string,
  }),
  toAddressData: PropTypes.shape({
    formatted_address: PropTypes.string,
    postcode: PropTypes.string,
  }),
  newLoading: PropTypes.bool,
  setPickupTime: PropTypes.func,
  setAttributes: PropTypes.func,
  setSuccessScreen: PropTypes.func,
  pickupTime: PropTypes.string,
  attributes: PropTypes.array,
  airportValue: PropTypes.string,
  jobId: PropTypes.string,
};
PickupComponent.propTypes = {
  setPickup: PropTypes.func,
  pickup: PropTypes.string,
  time: PropTypes.shape(String),
  date: PropTypes.shape(String),
  setTime: PropTypes.func,
  setDate: PropTypes.func,
  setPickupTime: PropTypes.func,
  airportValue: PropTypes.string,
  airportPickupTime: PropTypes.number,
  setAirportPickupTime: PropTypes.func,
  seatNum: PropTypes.string,
  setSeatNum: PropTypes.func,
};
