import style from "../styles/terms.module.scss";
import { Link } from "react-router-dom";


export default function us() {
  return (
    <section>
    
      <section className={style.content + " container"}>
        <section className={style.termsContent}>
        <h1>Seamless London Airport Transfers for USA Visitors</h1><br></br><br></br>
        <h2>Book Your Minicab to Heathrow or Gatwick from the USA</h2><br></br><br></br>
         <h3>Airport Bee - Your Reliable Airport Transfer Service in London</h3><br></br>
         <p>Planning a trip to London? Is youre flight arriving to London Heathrow, Gatwick airport, or London Stanstead airport? Let us take care of your transportation needs from the moment you land. At Cheetah Cars, we specialize in providing comfortable, reliable, and affordable taxi and minicab services tailored for travellers coming from the USA.Whether you're visiting for business or pleasure, alone or with your family, our professional drivers will ensure you start your journey in the UK stress-free.</p><br></br>
         
         <h3>Why Choose Airport Bee?</h3><br></br>
         <h3>Aiport Bee History</h3><br></br>
         <p>We have been serving customers from the USA travelling into London since 1986, we have an enviable reputation in the market for professionalism, security and time management. We have more than 1300 reviews on trip adviser with a near perfect score. Airport Bee is also a Transport for London approved licensed operator.</p><br></br>
         
         <h3>Effortless Online Booking</h3><br></br>
         <p>Book your taxi or minicab in advance from the comfort of your home or Office in the USA. Our user-friendly online booking sysytem is optimised specifically for personal and business travellers like you, ensuring a seamless booking experience.</p>
         <br></br>

         <h3>Fixed Rates & No Hidden Fees</h3><br></br>
         <p>We offer competitive, transparent pricing with no surprise charges. Know exactly what you'll pay before you arrive, so you can budget your trip with confidence.</p><br></br><br></br>
            

        <h3>Professional & Friendly Drivers</h3><br></br>
        <p>Our drivers are experienced, courteous, and knowledgeable about London. They'll greet you at the airport, assist with your luggage, and ensure you reach your destination safely and comfortably.</p><br></br>

        <h3>24/7 Availability</h3><br></br>
        <p>We operate around the clock to accommodate all flight schedules. Whether your flight arrives early in the morning or late at night, we'll be there to meet you.</p><br></br>
        
        <h3>Luxury Fleet for All Needs</h3><br></br>
        <p>Booking your Heathrow, Stanstead and Gatwick airport transfer has never been easier. Simply enter your flight details, destination, and select your preferred vehicle. We accept all major credit cards, so you can securely pay online in advance and enjoy a hassle-free arrival in London.</p><br></br>
        
        <h2>Easy Booking for USA Travellers</h2><br></br>
        <p>Airport Bee provides tailored airport transfer solutions for corporate travellers. With a fleet of executive saloons, they ensure seamless transportation to business meetings, conferences, and corporate events.</p><br></br>
        
        <h3>Your Journey, Our Priority</h3><br></br>
        <p>We understand that travelling can be stressful, especially when arriving in a new city. That's why our team is dedicated to providing a smooth, worry-free experience from start to finish. We monitor flight arrivals to adjust pickup times if your flight is delayed, so you can rest assured that we'll be there when you land.</p><br></br>

        <h3>Get a Quote Today</h3><br></br>
        <p>Planning your trip to London? Use our online booking tool to get an instant quote and reserve your taxi or minicab today. Experience the difference with Airport Bee- where your comfort and safety are our top priorities.</p><br></br>
        <br></br>
        <p>Let Airport Bee be your first choice for airport transfers when visiting London from the USA. Book now and enjoy peace of mind knowing your transportation is taken care of before you even take off!</p><br></br>

        </section>
        <div className={style.whySection}>
          <h4>WHY BOOK WITH US?</h4>
          <ul className={style.whylist}>
            <li>
              <h3>Reliable and Safe</h3>
              <p>
                Our drivers are insured and PCO certified - just like you would
                expect. However, we also spend time training our drivers. How
                many other private hire companies do that?
              </p>
            </li>
            <li>
              <h3>No hidden fees</h3>
              <p>
                You pay for what you use. With different payment options,
                including cash - using a Airport Bee driver even helps your
                wallet!
              </p>
            </li>
            <li>
              <h3>We're Always Here</h3>
              <p>
                Our office is open 24 hours a day - even on public holidays.
                This means you can count on us for your questions whatever the
                time of day or year.
              </p>
            </li>
            <Link to="/booknow" className={style.bookNow}>
              Book Now
            </Link>
          </ul>
        </div>
      </section>
    </section>
  );
}
