// dependencies
import style from "../../styles/Home.module.scss";
// importing images of partners
import cabwise from "../../assets/images/cab_yello.gif";
import cheetahcars from "../../assets/images/cheetahcars.png";
import cordic from "../../assets/images/cordic.png"
import one2one from "../../assets/images/one2one.png"
import safesupplier from "../../assets/images/safesupplier.png"
// import cabubble from "../../../public/images/cabubble.png";
// partners list
const partners = [cabwise,cheetahcars,cordic,one2one];

const affiliates = [
  { src: safesupplier,
    alt: "Helping create a safer and more sustainable world",
    href:"https://www.alcumus.com/en-gb/"
  },
  { src: cheetahcars,
    alt: "Minicabs in London",
    href:"https://www.cheetahcars.co.uk"
  },
  { src: cordic,
    alt: "Cordic Disptach Software",
    href:"https://www.cordic.com"
  },
  { src: one2one,
    alt:  "Minicabs in Maida Vale",
    href:"https://www.one2onecars.com"
  },
]
// partners layout section
export default function Partners() {
  return (
    <section className="container">
      <div className={style.partnersContainer}>
        <h2>Our Affiliates</h2><br></br>
        <ul>
          {affiliates.map((itm, indx) => (
            <li key={indx}>
              <a href={itm.href}><img src={itm.src} alt={itm.alt} /></a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
